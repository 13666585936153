import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import { MoralisProvider } from "react-moralis";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MoralisProvider
      serverUrl="https://vjof8n0dzhfu.usemoralis.com:2053/server"
      appId="OW7MFexszIgVLttN3wS4C6Tv8YYGGwMn26ieYJsH"
    >
      <App />
    </MoralisProvider>
  </React.StrictMode>
);
